<template>
  <auth-template title="Forgot Password">
    <div class="message is-warning">
      <section class="message-body">
        <div class="media">
          <div class="media-content">
            Forgot your password? No problem. Just let us know your email address and we will email you a password reset
            link that will allow you to choose a new one.
          </div>

        </div>
      </section>
    </div>
    <form v-on:submit.prevent="sendEmail()">
      <div class="field">
        <div class="control">
          <b-input type="email" maxlength="40" placeholder="Your Email"
                   v-model="email"></b-input>
        </div>
      </div>
      <button type="submit" class="button is-block is-info is-fullwidth" :disabled="sending">
        Email Password Reset Link <i class="fa fa-sign-in" aria-hidden="true"></i>
      </button>
      <h2 class="is-size-4" v-show="message" style="color:blue; word-wrap:break-word;">{{ message }}</h2>
    </form>
  </auth-template>
</template>

<script>
import AuthTemplate from "@/components/auth/AuthTemplate";

export default {
  name: "ForgotPassword",
  components: {AuthTemplate},
  data: function () {
    return {
      email: '',
      message: null,
      sending: false
    }
  },
  methods: {
    sendEmail: function () {
      this.sending = true;
      this.$http.post(process.env.VUE_APP_HOST + "/password/email", {email: this.email}).then(() => {
        this.message = 'We have emailed your password reset link!';
        this.sending = false;
      });
    }
  }
}
</script>

<style scoped>

</style>