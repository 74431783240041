<template>
  <section class="hero is-success is-fullheight backimage">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">

          <img src="@/assets/vertical1000.png" width="400" />

          <div class="is-size-3 has-text-black">{{title}}</div>

          <div class="box">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AuthTemplate",
  props: {
    title: String
  }
}
</script>

<style scoped>
.hero .nav, .hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.is-fullheight{
  min-height: 100vh;
}

input {
  font-weight: 300;
}

p {
  font-weight: 700;
}

p.subtitle {
  padding-top: 1rem;
}

.login-hr {
  border-bottom: 1px solid black;
}

.has-text-black {
  color: black;
}

.field {
  padding-bottom: 10px;
}

.fa {
  margin-left: 5px;
}
</style>